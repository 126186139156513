import React, { useState, useEffect, useRef } from 'react';
import { useSpring, useChain, animated } from 'react-spring';
import axios from 'axios';

import { ArrowRight, XCircle } from 'react-feather';

import { FormTextInput } from './Components/FormTextInput';
import { FormTextArea } from './Components/FormTextArea';

import Blackout from './assets/blackout.jpg';
import Sunscreen from './assets/sunscreen.jpg';
import PanelesOrientales from './assets/panelesOrientales.jpg';
import Mosquitero from './assets/mosquitero.png';
import Simple from './assets/simple.jpg';
import Doble from './assets/doble.jpg';
import Motorizada from './assets/motorizada.jpg';
import WhatsApp from './assets/whatsapp.png';
import Promo from './assets/promo.jpg';

import './App.css';

const designs = [
  {
    name: 'Cortina Blackout',
    text: 'Son ideales para filtrar la luz del sol y generar ambientes oscuros, de gran utilidad tanto para el hogar como para el ámbito empresarial y hotelero. Es una tela compuesta para cortinas a base de fibra de vidrio y recubierto con 3 láminas de PVC, especialmente diseñada para evitar los rayos UV. Actúa a su vez como aislante acústico y térmico.',
    img: Blackout,
  },
  {
    name: 'Cortina Sunscreen',
    text: 'Tela micro perforada al 5% de apertura, con calidad mertmet. Permite el acceso de luz al ambiente manteniendo la privacidad. Un sistema perfecto para agrandar el espacio. Puede usarse de forma complementaria con el blackout. Esta malla, a diferencia del blackout, permite el paso de la luz en el grado que se desee.',
    img: Sunscreen,
  },
  {
    name: 'Paneles Orientales',
    text: 'Es un sistema de rieles múltiples por los que corren paneles verticales de tela que se recogen lateralmente. Fabricados en variadas telas y texturas, se adaptan a diferentes estilos de decoración. La superposición total o parcial de los paneles aporta mayor o menor luminosidad y privacidad. Ideales para grandes ventanales como también para dividir ambientes cálidamente.',
    img: PanelesOrientales,
  },
  {
    name: 'Mosquitero Enrollable',
    text: 'Los mosquiteros enrollables conforman una muy efectiva protección contra insectos y su sistema retráctil permite retirarlo cuidando la estética sin generar suciedad. Permiten conservar las aberturas libres de obstáculos y garantizan la mejor protección contra mosquitos y otros tipos de insectos. Poseen un doble cepillo en el interior de las guías y un soporte lateral de nylon.',
    img: Mosquitero,
  },
];

const mechanisms = [
  {
    name: 'Mecanismo Simple',
    text: 'Nuestra opción más clásica. Consiste de un sistema básico con una sola tela.',
    img: Simple,
  },
  {
    name: 'Mecanismo Doble',
    text: 'La alternativa moderna de las cortinas roller black out es una solución formidable para resolver la temática oscuridad/termoregulación, la combinación con otras cortinas roller como las sunscreen representan la mejor resolución en cuanto a funcionalidad ya que abarcan ambas necesidades en este sistema dual de cortinas.',
    img: Doble,
  },
  {
    name: 'Mecanismo Motorizado',
    text: 'La instalación de sensores con aplicaciones de activación automática reduce los costos de energía hasta en un 30%. Esto se debe a que generan un mejor uso de la luz solar y la ventilación natural, lo que reduce el uso de aire acondicionado y luz eléctrica.',
    img: Motorizada,
  }
];

const App = () => {
  const [windowSize, setWindowSize] = useState({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth, orientation: window.document.documentElement.clientWidth > window.document.documentElement.clientHeight ? 'landscape' : 'portrait'});

  const isPhone = windowSize.orientation === 'portrait' && windowSize.width <= 767;

  const [webP, setWebP] = useState(null);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {  
    setWindowSize({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth, orientation: window.document.documentElement.clientWidth > window.document.documentElement.clientHeight ? 'landscape' : 'portrait'});
    document.getElementById("root").style.height = `${window.document.documentElement.clientHeight}px`;
    document.getElementById("root").style.overflow = `hidden`;

    if (window.document.documentElement.clientWidth <= 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    const checkForWebP = async () => {
      if (!window.self.createImageBitmap) return false;
      
      const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
      const blob = await fetch(webpData).then(r => r.blob());
      const supportsWebP = await createImageBitmap(blob).then(() => true, () => false);
      
      setWebP(supportsWebP ? 'webp' : 'png');
    }

    checkForWebP();
  }, []);

  useEffect(() => {
    const onResize = () => {
      setWindowSize({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth, orientation: window.document.documentElement.clientWidth > window.document.documentElement.clientHeight ? 'landscape' : 'portrait'});
      document.getElementById("root").style.height = `${window.document.documentElement.clientHeight}px`;
      document.getElementById("root").style.overflow = `hidden`;

      if (window.document.documentElement.clientWidth <= 1024) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const [openPromo, setOpenPromo] = useState(true);
  const promoProps = useSpring({ opacity: openPromo ? 1 : 0, pointerEvents: openPromo ? 'all' : 'none' });

  const [openValues, setOpenValues] = useState([true, false, false, false]);

  const firstProps = useSpring({
    from: { top: 0, height: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9, zIndex: 4 },
    to: { top: 0, height: openValues[0] ? windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9 : 0, zIndex: 4 }
  });

  const secondProps = useSpring({
    from: { bottom: 0, height: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9, zIndex: 3 },
    to: { top: 0, height: openValues[0] || openValues[1] ? windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9 : 0, zIndex: 3 }
  });

  const thirdProps = useSpring({
    from: { bottom: 0, height: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9, zIndex: 2 },
    to: { top: 0, height: openValues[0] || openValues[1] || openValues[2] ? windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9 : 0, zIndex: 2 }
  });

  const fourthProps = useSpring({
    from: { bottom: 0, height: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9, zIndex: 1 },
    to: { top: 0, height: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9, zIndex: 1 }
  });

  const [openTop, setOpenTop] = useState(false);

  const topSpringRef = useRef();

  const topInnerRef = useRef();

  useChain(openTop ? [{current: topSpringRef.current}, topInnerRef] : [topInnerRef, topSpringRef]);

  const [openBottom, setOpenBottom] = useState(false);

  const bottomSpringRef = useRef();

  const bottomInnerRef = useRef();

  useChain(openBottom ? [{current: bottomSpringRef.current}, bottomInnerRef] : [bottomInnerRef, bottomSpringRef]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [buttonStatus, setButtonStatus] = useState('Enviar');

  const contactButtonProps = useSpring({ backgroundColor: buttonStatus === 'Enviar' ? '#FD7F25' : buttonStatus === 'Enviando...' ? '#007bff' : buttonStatus === 'Error' ? '#dc3545' : '#28a745' });
  const contactTextProps = useSpring({ left: buttonStatus === 'Enviar' ? 'calc(1.5vw)' : buttonStatus === 'Enviando...' ? 'calc(5vw)' : buttonStatus === 'Error' ? 'calc(8vw)' : 'calc(8vw)' });

  const handleSubmit = () => {
    if (buttonStatus === 'Enviado!' ||buttonStatus === 'Error') {
      setButtonStatus("Enviar");
      setName("");
      setEmail("");
      setMessage("");
    } else {
      setButtonStatus("Enviando...");

      axios({
        method:'post',
        url: "https://us-central1-roller-tech.cloudfunctions.net/sendSupportEmail",
        data: {
          name: name,
          email: email,
          message: message
        }
        })
          .then((response) => {
            setButtonStatus("Enviado!");
          })
          .catch((error) => {
            console.log(error);
            setButtonStatus("Error");
          });
    }
  }

  const resetAllClicked = () => {
    setOpenTop(false);
    setOpenBottom(false);
  }

  const [showMeasureOverlay, setShowMeasureOverlay] = useState(false);

  const measureOverlayProps = useSpring({ height: windowSize.height * 0.95, opacity: showMeasureOverlay ? 1 : 0, pointerEvents: showMeasureOverlay ? 'all' : 'none' });

  return (
    <div className="appContainer">
      <a href="https://api.whatsapp.com/send?phone=+5491135678492" target="_blank" rel="noopener noreferrer">
        <img src={WhatsApp} alt="WhatsApp" className="whatsappLogo" />
      </a>
      <animated.div style={promoProps} className="promoOverlay" onClick={() => setOpenPromo(false)}>
        <div className="promoWrapper">
          <img src={Promo} alt="Promo 10% descuento presentado el código #PROMOWEB" className="promoImg" />
        </div>
      </animated.div>
      <animated.div style={measureOverlayProps} className="measureOverlay">
        <XCircle className="measureOverlayCloseIcon" onClick={() => setShowMeasureOverlay(false)} />
        <>
          <div className="measureOverlayColumn">
            <p className="measureOverlayTitle">Para cortinas fuera del marco</p>
            <img src={require('./assets/widthPlus.jpg')} alt="Medicion de ancho por fuera del marco" className="measureOverlayImg" />
            <img src={require('./assets/heightPlus.jpg')} alt="Medicion de alto por fuera del marco" className="measureOverlayImg" />
          </div>
          <div className="measureOverlayColumn">
            <p className="measureOverlayTitle">Para cortinas entre paredes o dentro del marco</p>
            <img src={require('./assets/width.jpg')} alt="Medicion de ancho por dentro del marco" className="measureOverlayImg" />
            <img src={require('./assets/height.jpg')} alt="Medicion de alto por dentro del marco" className="measureOverlayImg" />
          </div>
        </>
      </animated.div>
      <div className="navbar">
        <div className="navBtns">
          <p className="navBtn" style={{ color: openValues[0] ? '#FD7F25' : '#fff' }} onClick={() => setOpenValues([true, false, false, false])}>Inicio</p>
          <p className="navBtn" style={{ color: openValues[1] ? '#FD7F25' : '#fff' }} onClick={() => setOpenValues([false, true, false, false])}>Empresa</p>
          <p className="navBtn" style={{ color: openValues[2] ? '#FD7F25' : '#fff' }} onClick={() => setOpenValues([false, false, true, false])}>Productos</p>
          <p className="navBtn" style={{ color: openValues[3] ? '#FD7F25' : '#fff' }} onClick={() => setOpenValues([false, false, false, true])}>Cotizar</p>
          <p className="navBtn" onClick={() => setShowMeasureOverlay(true)}>Medir</p>
        </div>
      </div>
      <div className="sectionsContainer">
        <animated.div style={firstProps} className="section">
          <div className="headerImgContainer" style={{ height: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9 }}>
            <img src={isMobile ? require('./assets/rollers1.jpeg') : !webP ? null : webP === 'webp' ? require('./assets/rollers1.webp') : require('./assets/rollers1.jpeg')} alt="Cortinas Roller Encabezado" className="headerImg" />
          </div>
          <div className="headerInfoContainer" style={{ height: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9 }}>
            <img src={require('./assets/logoRoller.png')} alt="Logo Roller tech" className="logo" />
            {!isPhone && (
              <p className="headerInfoCTA" onClick={() => setOpenValues([false, false, true, false])}>
                Conocé nuestra línea de productos<span className="ctaGlyph"><ArrowRight size={windowSize.width > 1024 ? 20 : (windowSize.width >= 1024 && windowSize.orientation === 'portrait') ? 25 : windowSize > 767 ? 15 : 12} color="#5B6770" className="glyph" /></span>
              </p>
            )}
            {isPhone && (
              <div className="headerButtons">
                <a href="https://www.instagram.com/cortinasrollertech/?hl=es-la" target="_blank" rel="noopener noreferrer">
                  <img src={require('./assets/instagram.png')} alt="Logo Instagram" className="headerIcon" />
                </a>
                <a href="https://www.facebook.com/cortinasRollerTech" target="_blank" rel="noopener noreferrer">
                  <img src={require('./assets/facebook.png')} alt="Logo Facebook" className="headerIcon" />
                </a>
              </div>
            )}
          </div>
        </animated.div>
        <animated.div style={secondProps} className="section">
          <div className="usImgContainer" style={{ height: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9 }}>
            <img src={isMobile ? require('./assets/companyRollerMobile.jpg') : !webP ? null : webP === 'webp' ? require('./assets/companyRoller.webp') : require('./assets/companyRoller.jpg')} alt="Empleados trabajando" className="usImg" />
          </div>
          <div className="usInfoContainer" style={{ height: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9 }}>
            { windowSize.width <= 767 && windowSize.orientation === 'portrait'
            ? <p className="usInfoText">
                Empresa especializada en la fabricación, producción e instalación de cortinas roller y toldos a medida, con venta por mayor y menor.
                <br/><br/>
                Para todos nuestros productos, ofrecemos <strong>3 años de garantía, instalaciones en CABA y GBA, envíos a todo el país, visitas sin cargo y presupuestos en el acto.</strong>
              </p>
            : <p className="usInfoText">
                Empresa especializada en la fabricación, producción e instalación de cortinas roller y toldos a medida, con venta por mayor y menor.
                <br/><br/>
                Para todos nuestros productos, ofrecemos <strong>3 años de garantía, instalaciones en CABA y GBA, envíos a todo el país, visitas sin cargo y presupuestos en el acto.</strong>
                <br/><br/>
                Cortinas BlackOut, Cortinas SunScreen, o Cortinas Roller Dobles tanto en casas, oficinas como negocios, brindando la mejor calidad, con la rapidez en los trabajos que requiere cada caso y el mejor precio para todos nuestros clientes.
              </p>
            }
            <p className="usInfoCTA" onClick={() => setOpenValues([false, false, false, true])}>Consultanos<span className="ctaGlyph"><ArrowRight size={windowSize.width > 1024 ? 20 : (windowSize.width >= 1024 && windowSize.orientation === 'portrait') ? 25 : windowSize > 767 ? 15 : 12} color="#FD7F25" className="glyph" /></span></p>
          </div>
        </animated.div>
        <animated.div style={thirdProps} className="section">
          <div className="productsSection" style={{ height: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? windowSize.height * 0.84 : windowSize.height * 0.9 }} onClick={() => resetAllClicked()}>
            <div className="productsInner">
              <p className="productsInnerTitle">Diseños</p>
                {designs.map((design) => (
                  <div className="productContainer" key={design.name}>
                    <img src={design.img} alt={design.name} className="productImg" />
                    <div className="productInfo">
                      <p className="productsDisplayTitle">{design.name}</p>
                      <p className="productsDisplayText">{design.text}</p>
                      <p className="usInfoCTA productsCTA" onClick={() => setOpenValues([false, false, false, true])}>Asesorate con nosotros</p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="productsInner">
              <p className="productsInnerTitle">Mecanismos</p>
                {mechanisms.map((mechanism) => (
                  <div className="productContainer" key={mechanism.name}>
                    <img src={mechanism.img} alt={mechanism.name} className="productImg" />
                    <div className="productInfo">
                      <p className="productsDisplayTitle">{mechanism.name}</p>
                      <p className="productsDisplayText">{mechanism.text}</p>
                      <p className="usInfoCTA productsCTA" onClick={() => setOpenValues([false, false, false, true])}>Asesorate con nosotros</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </animated.div>
        <animated.div style={fourthProps} className="section">
          <div className="contactSection">
            <div className="contactInfoContainer">
              <img src={isMobile ? require('./assets/mapRollerMobile.jpg') : !webP ? null : webP === 'webp' ? require('./assets/mapRoller.webp') : require('./assets/mapRoller.jpg')} alt="Mapa Ubicación RollerTech" className="contactMap" />
              <p className="contactInfoText">
                Dirección: <br/>
                Soler 1050 - Burzaco<br/>
                Bs. As. - Argentina<br/><br/>

                Tel.: 155177.4340
              </p>
              <p className="contactInfoText rightContactInfoText">
                Horarios de Atención:<br/>
                Lun-Sab: 9 - 18 hs<br/><br/>

                Medios de pago: Efectivo y MercadoPago
              </p>
            </div>
            <div className="contactFormContainer">
              <FormTextInput placeholder="Nombre" value={name} onChange={setName} color='#666' />
              <FormTextInput placeholder="Email" value={email} onChange={setEmail} color='#666' />
              <FormTextArea placeholder="Mensaje" value={message} onChange={setMessage} color='#666' />
              <animated.button className="contactFormButton" style={contactButtonProps} disabled={name === '' || email === '' || message === ''} onClick={() => handleSubmit()}>
                <animated.p className="contactButtonText" style={contactTextProps} >{buttonStatus}</animated.p>
              </animated.button>
            </div>
          </div>
        </animated.div>
      </div>
      <div className="navbar noNavbarMobile">
        <div />
        <div className="navBtns">
          {/* <a href="https://api.whatsapp.com/send?phone=+5491135678492" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}><img src={require('./assets/whatsapp.png')} alt="Logo WhatsApp" className="socialIcon" /></a> */}
          <a href="https://www.instagram.com/cortinasrollertech/?hl=es-la" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}><p className="navBtn">Instagram</p></a>
          <a href="https://www.facebook.com/cortinasRollerTech" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}><p className="navBtn">Facebook</p></a>
        </div>
      </div>
    </div>
  );
}

export default App;
